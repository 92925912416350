import { Button, Flex, Textarea } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoResizeTextarea from 'react-textarea-autosize';
import { ChatContext } from '~src/chat/chat-context';
import { ChatRoomStatus } from '~src/chat/chat-types';
import { chatLogger } from '~src/chat/chat-utils';
import { ChatSendIcon } from '~src/components/KuuraIcon/icons/ChatSendIcon';

const MessageInput: React.FC = () => {
  const { activeRoomId, getChatRoomById, sendNewChatMessage, startTyping } =
    useContext(ChatContext);
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const roomStatus = getChatRoomById({ roomId: activeRoomId })?.status;
  const isRoomDisabled = roomStatus === ChatRoomStatus.Closed;

  const handleMessageSubmit = () => {
    if (activeRoomId === null || isRoomDisabled) return;
    chatLogger('Chat message form submitted', value);
    sendNewChatMessage(activeRoomId, value);
    setValue('');
  };

  return (
    <Flex padding={4} w="100%" justifyContent="center">
      <Textarea
        disabled={isRoomDisabled}
        as={AutoResizeTextarea}
        width={'100%'}
        m="auto"
        minRows={1}
        onChange={(event) => {
          startTyping();
          setValue(event.target.value);
        }}
        onKeyDown={(e: any) => {
          if (e.code === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleMessageSubmit();
          }
        }}
        placeholder={t('chat.discussion.messageInputPlaceholder') || ''}
        value={value}
        lineHeight="22px"
        fontSize="16px"
        fontWeight={500}
        borderRadius="1.5rem"
        minHeight="unset"
        paddingLeft={4}
        paddingRight={14}
        paddingY={3}
        style={{
          scrollbarWidth: 'thin',
        }}
      />
      <Button
        variant="link"
        onMouseDown={() => handleMessageSubmit()}
        position="absolute"
        bottom="28px"
        right="32px"
        zIndex={1}
      >
        <ChatSendIcon color="primary.500" fontSize={24} />
      </Button>
    </Flex>
  );
};

export default MessageInput;
