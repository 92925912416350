import React, { useContext, useEffect, useRef } from 'react';
import { Center, VStack } from '@chakra-ui/react';
import { ChatContext } from '~src/chat/chat-context';
import {
  ChatMessageDeliveryStatus,
  ChatterRole,
  ChatterStatus,
  ListedMessage,
} from '~src/chat/chat-types';
import useChatScroll from '~src/chat/useChatScroll';
import { chatLogger, getDoctorLatestMessage } from '~src/chat/chat-utils';
import TypingIndicator from '../TypingIndicator';
import Loading from '~src/components/Loading';
import { MessageBox } from '../MessageBox/MessageBox';

const MessageThread: React.FC = () => {
  const {
    activeRoomId,
    getRoomMessages,
    roomsMessagesLoaded,
    getChatRoomById,
    sendUpdateMessageStatusMessage,
    userInfo,
  } = useContext(ChatContext);

  const unreadMessageRef = useRef<string | null>(null);
  const { userGuid } = userInfo;
  const roomMessagesIsLoading = !roomsMessagesLoaded[activeRoomId ?? ''];

  const messages = getRoomMessages({ roomId: activeRoomId ?? '' });
  const chatRoom = getChatRoomById({ roomId: activeRoomId });
  const doctorMember = chatRoom?.members.find(
    (member) => member.role === ChatterRole.Doctor,
  );

  const isDoctortWriting =
    !!doctorMember && doctorMember?.status === ChatterStatus.Writing;

  const { containerRef, messagesEndRef } = useChatScroll(
    messages,
    isDoctortWriting,
    activeRoomId,
  );
  const newestUnreadMessage =
    (chatRoom?.unreadMessageCount || 0) > 0 &&
    getDoctorLatestMessage({
      messages,
      userGuid,
    });

  useEffect(() => {
    if (
      activeRoomId &&
      newestUnreadMessage &&
      unreadMessageRef.current !== newestUnreadMessage.messageId &&
      newestUnreadMessage.senderGuid &&
      newestUnreadMessage.senderGuid !== userGuid
    ) {
      const { messageId } = newestUnreadMessage;
      unreadMessageRef.current = messageId;
      chatLogger(
        'Sending message to change this and previous messages as read:',
        newestUnreadMessage,
      );
      sendUpdateMessageStatusMessage({
        roomId: activeRoomId,
        messageId,
        status: ChatMessageDeliveryStatus.Read,
      });
    }
  }, [
    userGuid,
    sendUpdateMessageStatusMessage,
    activeRoomId,
    chatRoom,
    newestUnreadMessage,
  ]);

  const messageList =
    messages &&
    messages
      .sort((a: ListedMessage, b: ListedMessage) =>
        a?.createdTimestamp &&
        b?.createdTimestamp &&
        a.createdTimestamp < b.createdTimestamp
          ? -1
          : 1,
      )
      .map((message: ListedMessage) => {
        return (
          <MessageBox
            key={message.messageId}
            userGuid={userGuid}
            doctorGuid={doctorMember?.guid}
            message={message}
          />
        );
      });

  return (
    <VStack ref={containerRef} spacing={6} bg="white">
      {roomMessagesIsLoading && (
        <Center>
          <Loading size={'md'} />
        </Center>
      )}
      {messageList}
      {isDoctortWriting && <TypingIndicator />}
      <div ref={messagesEndRef} />
    </VStack>
  );
};

export default MessageThread;
