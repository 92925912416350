import React, { useContext, useEffect } from 'react';
import { Container, Box, Flex } from '@chakra-ui/react';
import MessageThread from '~/src/components/Chat/MessageThread';
import MessageInput from '~/src/components/Chat/MessageInput';
import DiscussionHeader from '../DiscussionHeader';
import { useHistory } from 'react-router-dom';
import { ChatContext, CHAT_LOGIN_URL_PATH } from '~src/chat/chat-context';
import { useChatTokens } from '~src/chat/useChatTokens';
import { AppContext } from '~src/state';

const Discussion: React.FC = () => {
  const history = useHistory();
  const {
    userInfo: { chatRoomId },
    clearChatContext,
  } = useContext(ChatContext);
  const { dispatch } = useContext(AppContext);
  const { userSessionId } = useChatTokens(chatRoomId);

  useEffect(() => {
    if (!userSessionId) {
      clearChatContext();
      history.push(CHAT_LOGIN_URL_PATH + '/' + chatRoomId);
    }
  }, [userSessionId, history, chatRoomId, clearChatContext, dispatch]);

  return (
    <Box position="relative">
      <Container
        marginTop={[0, 0, 6]}
        marginX={[0, 0, 'auto']}
        maxWidth={['100%', '100%', '554px']}
        paddingX={0}
        display="flex"
        position="relative"
        flexDirection="column"
        bg="white"
        borderRadius={[0, 0, 8]}
        h={[
          'calc(100vh - 4.625rem)',
          'calc(100vh - 4.625rem)',
          'calc(100vh - 85px - 32px)',
        ]}
        justifyContent="space-between"
      >
        <Flex
          grow={1}
          direction="column"
          overflowY="auto"
          paddingX="18px"
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#C7C8CA transparent',
          }}
        >
          <DiscussionHeader />
          <MessageThread />
        </Flex>

        <MessageInput />
      </Container>
    </Box>
  );
};

export default Discussion;
