import { ListedMessage, RoomInfo, WebsocketMessageType } from './chat-types';
import {
  authorizationMessageSchema,
  clientStatusUpdateMessageSchema,
  enterRoomRequestMessageSchema,
  errorMessageSchema,
  exitRoomRequestMessageSchema,
  getRoomMessagesRequestSchema,
  listRoomsRequestMessageSchema,
  newChatMessageSchema,
  pingMessageSchema,
  roomListMessageSchema,
  roomMessageListMessageSchema,
  updateMessageStatusMessageSchema,
  updateRoomStatusMessageSchema,
} from './chat-zod-schemas';
import { z } from 'zod';
import { CHAT_LOGGER_ENABLED } from './chat-context';
import { devlogger } from '~src/utils';

export const chatLogger = (
  message?: unknown,
  ...optionalParams: unknown[]
): void =>
  CHAT_LOGGER_ENABLED && devlogger('[CHAT]:', message, ...optionalParams);

export const sortChatRooms = (rooms: RoomInfo[]): RoomInfo[] =>
  rooms.slice().sort((a, b) => {
    if (!a.lastActive) return 1; // a goes to the end if it doesn't have lastActive
    if (!b.lastActive) return -1; // b goes to the end if it doesn't have lastActive
    return new Date(b.lastActive).getTime() - new Date(a.lastActive).getTime();
  });

const chatMessageValidationSchemas = {
  [WebsocketMessageType.ClientStatusUpdateMessage]:
    clientStatusUpdateMessageSchema,
  [WebsocketMessageType.AuthorizationMessage]: authorizationMessageSchema,
  [WebsocketMessageType.ListRoomsRequestMessage]: listRoomsRequestMessageSchema,
  [WebsocketMessageType.RoomListMessage]: roomListMessageSchema,
  [WebsocketMessageType.NewChatMessage]: newChatMessageSchema,
  [WebsocketMessageType.EnterRoomRequestMessage]: enterRoomRequestMessageSchema,
  [WebsocketMessageType.ExitRoomRequestMessage]: exitRoomRequestMessageSchema,
  [WebsocketMessageType.UpdateRoomStatusMessage]: updateRoomStatusMessageSchema,
  [WebsocketMessageType.GetRoomMessagesRequestMessage]:
    getRoomMessagesRequestSchema,
  [WebsocketMessageType.RoomMessagesListMessage]: roomMessageListMessageSchema,
  [WebsocketMessageType.UpdateMessageStatusMessage]:
    updateMessageStatusMessageSchema,
  [WebsocketMessageType.PingMessage]: pingMessageSchema,
  [WebsocketMessageType.ErrorMessage]: errorMessageSchema,
} as const;

const websocketMessageSchema = z.object({
  messageType: z.nativeEnum(WebsocketMessageType),
  timeStamp: z.string(),
});

export type WebsocketMessage = {
  messageType: WebsocketMessageType;
  timeStamp: string;
};

export const isWebsocketMessage = (
  message: unknown,
): message is WebsocketMessage =>
  websocketMessageSchema.safeParse(message).success;

type ChatMessageType = keyof typeof chatMessageValidationSchemas;
type ChatMessage = z.infer<
  (typeof chatMessageValidationSchemas)[ChatMessageType]
>;

export const isChatMessageType = (
  messageType: WebsocketMessageType,
): messageType is ChatMessageType =>
  messageType in chatMessageValidationSchemas;

export const isValidChatMessage = (message: unknown): ChatMessage | null => {
  if (!isWebsocketMessage(message)) return null;
  const { messageType } = message;
  if (!isChatMessageType(messageType)) return null;
  const result = chatMessageValidationSchemas[messageType].safeParse(message);
  if (!result.success) {
    chatLogger('Error validating chat message.', result.error);
    // Question: Should we send error messages to Sentry also from digitk?
    //captureException(result.error);
    return null;
  }
  return result.data;
};

// Generic function to update an array element based on a property value
export function updateByProperty<T, K extends keyof T>(
  array: T[],
  key: K,
  value: T[K],
  newPropValues: Partial<T>,
): T[] {
  return array.map((item) =>
    item[key] === value ? { ...item, ...newPropValues } : item,
  );
}

// Higher-order function to create type guards
function createTypeGuard<T extends ChatMessageType>(
  schema: z.ZodTypeAny,
  messageType: T,
): (
  message: WebsocketMessage,
) => message is z.infer<(typeof chatMessageValidationSchemas)[T]> {
  return (
    message: WebsocketMessage,
  ): message is z.infer<(typeof chatMessageValidationSchemas)[T]> => {
    const result = schema.safeParse(message);
    const isValid = message.messageType === messageType && result.success;
    if (!isValid) {
      chatLogger(
        `Invalid message type or schema. messageType: '${messageType}'`,
        '\nValidated schema:',
        message,
        '\nerrors: ',
        !result.success ? result.error.errors : 'messageType mismatch',
      );
    }
    return isValid;
  };
}

// Create type guards dynamically
export const isClientStatusUpdateMessage = createTypeGuard(
  clientStatusUpdateMessageSchema,
  WebsocketMessageType.ClientStatusUpdateMessage,
);

export const isRoomListMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.RoomListMessage],
  WebsocketMessageType.RoomListMessage,
);

export const isNewChatMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.NewChatMessage],
  WebsocketMessageType.NewChatMessage,
);

export const isListRoomsRequestMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.ListRoomsRequestMessage],
  WebsocketMessageType.ListRoomsRequestMessage,
);

export const isEnterRoomRequestMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.EnterRoomRequestMessage],
  WebsocketMessageType.EnterRoomRequestMessage,
);

export const isExitRoomRequestMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.ExitRoomRequestMessage],
  WebsocketMessageType.ExitRoomRequestMessage,
);

export const isGetRoomMessagesRequest = createTypeGuard(
  chatMessageValidationSchemas[
    WebsocketMessageType.GetRoomMessagesRequestMessage
  ],
  WebsocketMessageType.GetRoomMessagesRequestMessage,
);

// Only from server to client
export const isRoomMessageListMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.RoomMessagesListMessage],
  WebsocketMessageType.RoomMessagesListMessage,
);

export const isUpdateRoomStatusMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.UpdateRoomStatusMessage],
  WebsocketMessageType.UpdateRoomStatusMessage,
);

export const isUpdateMessageStatusMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.UpdateMessageStatusMessage],
  WebsocketMessageType.UpdateMessageStatusMessage,
);

export const isPingMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.PingMessage],
  WebsocketMessageType.PingMessage,
);

export const isErrorMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.ErrorMessage],
  WebsocketMessageType.ErrorMessage,
);

export const isAuthorizationMessage = createTypeGuard(
  chatMessageValidationSchemas[WebsocketMessageType.AuthorizationMessage],
  WebsocketMessageType.AuthorizationMessage,
);

// TODO: if not needed in digitk remove this function
export function getNameInitials(name: string): string {
  return (
    name
      .split(/\s+/)
      .map((word) => word.charAt(0))
      .slice(0, 2)
      .join('') ?? '??'
  ); // Join the letters together or use '??' if no name
}

export const getDoctorLatestMessage = ({
  messages,
  userGuid,
}: {
  messages: ListedMessage[];
  userGuid: string | undefined;
}): ListedMessage => {
  const latestMessage = messages
    .filter(
      (message: ListedMessage) =>
        message.senderGuid && message.senderGuid !== userGuid,
    )
    .sort((a: ListedMessage, b: ListedMessage) =>
      a.createdTimestamp &&
      b.createdTimestamp &&
      a.createdTimestamp < b.createdTimestamp
        ? -1
        : 1,
    )
    .slice(-1)[0];

  return latestMessage;
};

export const sliceFirstAndLastName = (name: string) => {
  const lastSpaceIndex = name.lastIndexOf(' ');

  const givenNames =
    lastSpaceIndex !== -1 ? name.slice(0, lastSpaceIndex) : name;

  const lastName = lastSpaceIndex !== -1 ? name.slice(lastSpaceIndex + 1) : '';
  return { givenNames, lastName };
};
