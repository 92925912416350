import { useCallback, useState } from 'react';
import {
  ChatContextType,
  CHAT_LOGIN_URL_PATH,
  defaultChatSettings,
  defaultChatUser,
  PING_INTERVAL,
  PING_TIMEOUT,
} from './chat-context';
import { v4 as uuidv4 } from 'uuid';
import {
  ListRoomsFilter,
  WebsocketMessageType,
  EnterRoomRequestMessage,
  GetRoomMessagesRequest,
  ChatMessageDeliveryStatus,
  ChatMessageContentType,
  RoomInfo,
  ChatterStatus,
  ExitRoomRequestMessage,
  ListedMessage,
  ChatUser,
  ChatSettings,
  ChatStatus,
  ClientStatusUpdateMessage,
  ChatterRole,
} from './chat-types';
import {
  WebsocketConnectionName,
  useDuodecimWebsocket,
} from './useDuodecimWebsocket';
import {
  chatLogger,
  isClientStatusUpdateMessage,
  isEnterRoomRequestMessage,
  isErrorMessage,
  isExitRoomRequestMessage,
  isGetRoomMessagesRequest,
  isListRoomsRequestMessage,
  isNewChatMessage,
  isPingMessage,
  isRoomListMessage,
  isRoomMessageListMessage,
  isUpdateMessageStatusMessage,
  isUpdateRoomStatusMessage,
  isValidChatMessage,
  sliceFirstAndLastName,
  sortChatRooms,
  updateByProperty,
  WebsocketMessage,
} from './chat-utils';
import { find, uniqBy } from 'lodash';
import { useTypingStatus } from './useTypingStatus';
//import { toastError } from '../utils/toasts';
import { formatDate, getCurrentDateTimeISO } from '~src/utils/formatTime';
import { useChatTokens } from './useChatTokens';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const defaultRoomsFilter: ListRoomsFilter = {
  activeWithinHours: 999999,
};

/**
 * Custom hook to handle chat messaging over websocket
 * - function names starting handle<something> handles incoming websocket messages
 * - function names starting send<something> sends messages over websocket
 *
 * @param duodecimApp
 * @returns {ChatContextType}
 */
const useChat = (): ChatContextType => {
  const history = useHistory();
  const [chatError, setChatError] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<ChatUser>(defaultChatUser);
  const { chatRoomId, userGuid, status: userChatStatus } = userInfo;
  const { accessToken, destroyChatSessionStorage } = useChatTokens(chatRoomId);
  const patientFirstNameLastName = `${userInfo.givenNames} ${userInfo.lastName}`;
  const [chatSettings, setChatSettings] =
    useState<ChatSettings>(defaultChatSettings);
  const [activeRoomId, setActiveRoomId] = useState<string | null>(null);
  const [chatRooms, setChatRooms] = useState<
    ChatContextType['chatRooms'] | null
  >(null);
  const [roomsMessagesLoaded, setRoomsMessagesLoaded] = useState<
    Record<string, string>
  >({});

  const [roomsMessages, setRoomsMessages] = useState<
    ChatContextType['roomsMessages']
  >({});

  const upsertChatSettings = (
    settings: Partial<ChatContextType['chatSettings']>,
  ) => setChatSettings((prev) => ({ ...prev, ...settings }));

  const getChatRoomById = ({
    roomId,
  }: {
    roomId: string | null | undefined;
  }): RoomInfo | null => (roomId ? find(chatRooms, { roomId }) || null : null);

  /**
   * Set unread message count for room
   *  - newMessageCount if provided, otherwise increase by increaseBy (default 1)
   *
   * @param roomId
   * @param increaseBy
   * @param newMessageCount
   */
  const updateRoomUnreadMessageCount = (props: {
    roomId: string;
    newMessageCount?: number;
    increaseBy?: number;
  }) => {
    const { roomId, newMessageCount, increaseBy } = props;
    setChatRooms((prev) => {
      const room = find(prev, { roomId });
      if (!room) return prev;

      return sortChatRooms(
        updateByProperty(prev || [], 'roomId', roomId, {
          unreadMessageCount:
            newMessageCount ?? room.unreadMessageCount + (increaseBy ?? 1),
        }),
      );
    });
  };

  // websocket message handlers
  const handleMessageFunctions: Partial<
    Record<WebsocketMessageType, (message: WebsocketMessage) => void>
  > = {
    [WebsocketMessageType.ClientStatusUpdateMessage]:
      handleClientStatusUpdateMessage,
    [WebsocketMessageType.RoomListMessage]: handleRoomListMessage,
    [WebsocketMessageType.NewChatMessage]: handleNewChatMessage,
    [WebsocketMessageType.UpdateRoomStatusMessage]:
      handleUpdateRoomStatusMessage,
    [WebsocketMessageType.RoomMessagesListMessage]:
      handleRoomMessagesListMessage,
    [WebsocketMessageType.UpdateMessageStatusMessage]:
      handleUpdateMessageStatusMessage,
    [WebsocketMessageType.PingMessage]: handlePingMessage,
    [WebsocketMessageType.ErrorMessage]: handleErrorMessage,
  };

  const timeStampNow = new Date().toISOString();

  const validateAndParseMessage = (
    message: unknown,
  ): WebsocketMessage | null => {
    const parsedMessage: unknown = JSON.parse(message as string);
    if (!isValidChatMessage(parsedMessage)) {
      chatLogger('Not valid websocket message received', parsedMessage);
      return null;
    }
    return parsedMessage as WebsocketMessage;
  };

  const { sendJsonMessage } = useDuodecimWebsocket(
    accessToken,
    WebsocketConnectionName.Chat,
    {
      onMessage: (event: MessageEvent<string>) => {
        if (!accessToken) return;
        try {
          const message = validateAndParseMessage(event.data);
          if (!message) return;

          const handlerFunction = handleMessageFunctions[message.messageType];
          handlerFunction
            ? handlerFunction(message)
            : chatLogger('No handler function for message type', message);
        } catch (error) {
          chatLogger('Error handling websocket chat message', error, event);
        }
      },
      heartbeat: {
        message: JSON.stringify({
          messageType: WebsocketMessageType.PingMessage,
          timeStamp: new Date().toISOString(),
        }),
        //returnMessage: (string) - we can't use cause we dont know ping message timeStamp server sent
        timeout: PING_TIMEOUT, // 30 s, if no response is received, the connection will be closed
        interval: PING_INTERVAL, // every 30 seconds, a ping message will be sent
      },
    },
    {
      // TODO: remove if not needed
      afterOnOpen: () => {},
    },
  );

  function handlePingMessage(message: WebsocketMessage) {
    if (!isPingMessage(message)) return;
    chatLogger('PingMessage received', message);
  }

  function handleDoctorPickedupPatient(message: ClientStatusUpdateMessage) {
    const { roomId, guid } = message;
    const chatRoom = getChatRoomById({ roomId });
    if (!chatRoom) return;
    const doctorMember = chatRoom.members.find(
      (member) => member.role === ChatterRole.Doctor,
    );
    if (!doctorMember && guid && guid !== userGuid) {
      // doctor picked up patient, request rooms again to get updated status
      sendListRoomsRequestMessage();
    }
  }

  function handleClientStatusUpdateMessage(message: WebsocketMessage): void {
    if (!isClientStatusUpdateMessage(message)) return;
    chatLogger('ClientStatusUpdateMessage received', message);
    const { firstAndLastName, status, roomId, guid } = message;

    handleDoctorPickedupPatient(message);

    if (!userGuid && guid) {
      const isAfterLoggedInCase =
        roomId && userChatStatus !== ChatStatus.LoggedOut;
      if (isAfterLoggedInCase) {
        setUserInfo((prev) => ({
          ...prev,
          ...sliceFirstAndLastName(firstAndLastName),
          userGuid: guid,
        }));
        setActiveRoom({ roomId });
      }

      const isAfterPageRefreshCase =
        !roomId && userChatStatus === ChatStatus.LoggedOut;
      if (isAfterPageRefreshCase) {
        setUserInfo((prev) => ({
          ...prev,
          status: ChatStatus.LoggedIn,
        }));
        sendListRoomsRequestMessage();
      }
    }

    // TODO: ask backend session to be expired after 1min to test this
    const isAfterSessionRefreshCase =
      userGuid &&
      guid === userGuid &&
      activeRoomId &&
      userChatStatus === ChatStatus.LoggedIn;
    if (isAfterSessionRefreshCase) {
      chatLogger(
        'ClientStatusUpdateMessage, isAfterSessionRefreshCase',
        isAfterSessionRefreshCase,
      );
      sendEnterRoomRequestMessage({ roomId: activeRoomId });
    }

    const memberExists = getChatRoomById({ roomId })?.members.find(
      (member) => member.guid === guid,
    );

    // - skip if member already removed from room
    if (!roomId || !guid || !memberExists) return;
    // update doctor status on specific room
    setChatRooms((prev: RoomInfo[] | null) =>
      updateByProperty(prev || [], 'roomId', roomId, {
        members: updateByProperty(
          find(prev, { roomId })?.members || [],
          'guid',
          guid,
          { status },
        ),
      }),
    );
  }

  function setActiveRoom({ roomId }: { roomId: string | null }): void {
    /* load room messages if not yet loaded */
    if (roomId && !roomsMessagesLoaded[roomId]) {
      chatLogger(
        'Active room set, messages not loaded, GetRoomMessagesRequestMessage -> expecting RoomMessagesListMessage. roomId',
        roomId,
      );
      sendGetRoomMessagesRequestMessage({
        roomId,
        since: formatDate(moment().subtract(365, 'days')), // QUESTION: which date value to use / should we get all messages?
        take: 500, // TODO-v2: should we add infinite scroll for messages? atleast amount of chatRoom.unreadMessageCount ?
        skip: 0,
      });
    }

    setActiveRoomId(roomId);
    return;
  }

  /**
   * Update client status on specific room, only Online / Writing -allowed
   *
   * @param {status: ChatterStatus, roomId: string}
   * @returns {void}
   */
  const sendClientStatusUpdateMessage = useCallback(
    ({
      status = ChatterStatus.Online,
      roomId,
    }: {
      status: ChatterStatus;
      roomId: string;
    }): void => {
      const payload = {
        messageType: WebsocketMessageType.ClientStatusUpdateMessage,
        timeStamp: new Date().toISOString(),
        firstAndLastName: patientFirstNameLastName,
        status,
        roomId,
      };
      if (!isClientStatusUpdateMessage(payload)) return;
      sendJsonMessage(payload);
    },
    [patientFirstNameLastName, sendJsonMessage],
  );

  const { startTyping } = useTypingStatus(
    activeRoomId,
    sendClientStatusUpdateMessage,
  );

  function sendListRoomsRequestMessage(props?: {
    filter: ListRoomsFilter;
  }): void {
    const { filter } = props ?? {};
    const payload = {
      messageType: WebsocketMessageType.ListRoomsRequestMessage,
      timeStamp: timeStampNow,
      filter: {
        ...defaultRoomsFilter,
        ...filter,
      },
    };
    if (!isListRoomsRequestMessage(payload)) return;
    chatLogger(
      'sent ListRoomsRequestMessage, expecting RoomListMessage',
      payload,
    );

    sendJsonMessage(payload);
    return;
  }

  /** Response message to ListRoomsRequestMessage */
  function handleRoomListMessage(message: WebsocketMessage): void {
    if (!isRoomListMessage(message)) return;
    chatLogger('RoomListMessage received', message);
    const newRooms = message.rooms ?? [];
    setChatRooms(sortChatRooms(newRooms));

    if (chatRoomId && newRooms?.find((room) => room.roomId === chatRoomId)) {
      sendEnterRoomRequestMessage({
        roomId: chatRoomId,
      });
    } else {
      //alert(`User does not have access to this room ${chatRoomId}`);
      //history.push(`/chat/login/${chatRoomId}`);
    }
  }

  const sendNewChatMessage = (roomId: string, message: string): void => {
    const newMessage = {
      messageType: WebsocketMessageType.NewChatMessage,
      timeStamp: getCurrentDateTimeISO(),
      messageId: uuidv4(),
      contentType: ChatMessageContentType.Text,
      content: message,
      roomId: roomId,
      senderName: patientFirstNameLastName,
    };
    if (!isNewChatMessage(newMessage)) return;
    // Update from Writing to Online
    sendClientStatusUpdateMessage({ status: ChatterStatus.Online, roomId });
    sendJsonMessage(newMessage);
  };

  function handleNewChatMessage(message: WebsocketMessage): void {
    if (!isNewChatMessage(message)) return;
    chatLogger('NewChatMessage received', message);
    const { roomId, senderGuid } = message;
    if (!roomId) return;

    const isOwnMessage = senderGuid === userGuid;
    const newMessage = {
      ...message,
      status: ChatMessageDeliveryStatus.Delivered,
    };
    setRoomsMessages((prev) => ({
      ...prev,
      [roomId]: uniqBy([...(prev[roomId] ?? []), newMessage], 'messageId'),
    }));

    chatLogger(
      `${
        isOwnMessage ? 'Own' : senderGuid ? 'Doctor send' : 'System message'
      } NewChatMessage `,
      message,
    );
    // TODO: update condition after system message TYPEs added
    //if (content === 'Keskustelu suljettiin') {}

    // update chat room unread message count
    // - system message status not managed, should we anyway show new message for user?
    // - if own message, mark as delivered - "lukematta"
    //senderGuid &&
    !isOwnMessage && updateRoomUnreadMessageCount({ roomId, increaseBy: 1 });
  }

  function sendEnterRoomRequestMessage({ roomId }: { roomId: string }): void {
    const payload: EnterRoomRequestMessage = {
      messageType: WebsocketMessageType.EnterRoomRequestMessage,
      timeStamp: timeStampNow,
      roomId,
    };
    if (!isEnterRoomRequestMessage(payload)) return;

    sendJsonMessage(payload);
    chatLogger(
      'sent EnterRoomRequestMessage, expecting ClientStatusUpdateMessage',
      payload,
    );
  }

  /*
  // CASE: doctor sent patient back to queue
  function handleMemberLeaveRoom(message: ClientStatusUpdateMessage) {
    setChatRooms((prev) =>
        updateByProperty(prev || [], 'roomId', roomId, {
          members: find(prev, { roomId })?.members.filter(
            (member) => member.role !== ChatterRole.Doctor,
          ),
        }),
      );
   }
  */

  /**
   * @param {roomId: string}
   * @returns {void}
   */
  function sendExitRoomRequestMessage({ roomId }: { roomId: string }): void {
    const payload: ExitRoomRequestMessage = {
      messageType: WebsocketMessageType.ExitRoomRequestMessage,
      timeStamp: timeStampNow,
      roomId,
    };
    if (!isExitRoomRequestMessage(payload)) return;
    sendJsonMessage(payload);
    chatLogger(
      'sent ExitRoomRequestMessage, expecting ClientStatusUpdateMessage',
      payload,
    );
  }

  function handleUpdateRoomStatusMessage(message: WebsocketMessage): void {
    if (!isUpdateRoomStatusMessage(message)) return;

    const { status, roomId } = message;
    if (!roomId || !status) return;
    setChatRooms((prev) =>
      updateByProperty(prev || [], 'roomId', roomId, { status }),
    );
  }

  function sendGetRoomMessagesRequestMessage(props: {
    roomId: string;
    since?: string;
    take?: number;
    skip?: number;
  }): void {
    const payload: GetRoomMessagesRequest = {
      messageType: WebsocketMessageType.GetRoomMessagesRequestMessage,
      timeStamp: timeStampNow,
      ...props,
    };
    if (!isGetRoomMessagesRequest(payload)) return;

    chatLogger(
      'GetRoomMessagesRequestMessage sent, expexting RoomMessageListMessage -event',
      payload,
    );
    sendJsonMessage(payload);
  }

  function handleRoomMessagesListMessage(message: WebsocketMessage): void {
    if (!isRoomMessageListMessage(message)) return;
    chatLogger('RoomMessagesListMessage received', message);
    try {
      const { roomId, messages } = message;
      if (!messages) return;
      setRoomsMessages((prev) => ({
        ...prev,
        [roomId]: uniqBy(
          [...(roomsMessages[roomId] || []), ...messages],
          'messageId',
        ),
      }));
      // TODO-v2: add infinite scroll for messages?
      setRoomsMessagesLoaded((prev) => ({ ...prev, [roomId]: timeStampNow }));
    } catch (error) {
      chatLogger('Error handling RoomMessagesListMessage', error, message);
    }
  }

  function sendUpdateMessageStatusMessage({
    roomId,
    messageId,
    status,
  }: {
    roomId: string | null;
    messageId: string;
    status: ChatMessageDeliveryStatus;
  }): void {
    const payload = {
      messageType: WebsocketMessageType.UpdateMessageStatusMessage,
      timeStamp: timeStampNow,
      messageId: messageId,
      status: status ?? ChatMessageDeliveryStatus.Read,
    };
    if (!isUpdateMessageStatusMessage(payload) || !roomId) return;
    chatLogger(
      'sent UpdateMessageStatusMessage, expecting UpdateMessageStatusMessage',
      payload,
    );
    sendJsonMessage(payload);
  }

  function handleUpdateMessageStatusMessage(message: WebsocketMessage): void {
    if (!isUpdateMessageStatusMessage(message) || !message?.status) return;
    const { roomId, status, messageId } = message;
    // update chat room unread message status
    if (!roomId) return; // should not be possible

    // update chat room unread message count
    // HOX: when last message is read, all earlier messages are marked as read also
    // - server send back UpdateMessageStatusMessage for every message marked as read
    const room = getChatRoomById({ roomId });
    (room?.unreadMessageCount || 0) > 0 &&
      updateRoomUnreadMessageCount({ roomId, newMessageCount: 0 });

    setRoomsMessages((prev: Record<string, ListedMessage[]>) => ({
      ...prev,
      [roomId]: updateByProperty(prev[roomId], 'messageId', messageId, {
        status,
      }),
    }));
    chatLogger('UpdateMessageStatusMessage received', message);
  }

  function handleErrorMessage(message: WebsocketMessage): void {
    if (!isErrorMessage(message) || !accessToken) return;
    chatLogger('ErrorMessage received', message);
    if (message.errorCode === 'InvalidSession') {
      clearChatContext();
      setChatError('Chat -istuntosi on vanhentunut, kirjaudu uudelleen');
      history.push(`${CHAT_LOGIN_URL_PATH}/${chatRoomId || ''}`);
      /*
      // QUESTION: if userSessionId found in sessionStorage, 
      // do we want to create new guest session token and not throw user out of chat?
      if (userSessionId)
        void createGuestSession({ variables: { body: { userSessionId } } });
      else clearChatContext();
      */
    }
    // TODO: how we show error messages to user in digitk?
    //toastError(`Virhe chat -keskustelussa, yritä uudestaan!`);
  }

  const clearChatContext = () => {
    activeRoomId && sendExitRoomRequestMessage({ roomId: activeRoomId });
    setUserInfo((prev) => ({
      ...defaultChatUser,
      chatRoomId: prev.chatRoomId,
    }));
    setActiveRoomId(null);
    setChatRooms(null);
    setRoomsMessages({});
    setRoomsMessagesLoaded({});
    destroyChatSessionStorage();
  };

  return {
    chatError,
    setChatError,
    userInfo,
    setUserInfo,
    chatSettings,
    upsertChatSettings,
    activeRoomId,
    setActiveRoom,
    chatRooms,
    setChatRooms,
    sendNewChatMessage,
    sendUpdateMessageStatusMessage,
    sendClientStatusUpdateMessage,
    sendEnterRoomRequestMessage,
    sendExitRoomRequestMessage,
    sendListRoomsRequestMessage,
    getRoomMessages: ({ roomId }: { roomId: string }) =>
      roomsMessages[roomId] ?? [],
    roomsMessages,
    roomsMessagesLoaded,
    sendWsMessage: sendJsonMessage,
    getChatRoomById,
    startTyping,
    clearChatContext,
  };
};

export { useChat };
