import { FlowResponse, GetStartMenuQuery } from '~/src/api';
import type {
  Colors,
  SpaceProps,
  LayoutProps,
  EffectProps,
  BackgroundProps,
  BorderProps,
} from '@chakra-ui/react';

export enum Configuration {
  Default = 'Default',
  Espoo = 'Espoo',
  Helsinki = 'Helsinki',
  Terapianavigaattori = 'Terapianavigaattori',
  Interventionavigaattori = 'Interventionavigaattori',
  Tupakkalakko = 'Tupakkalakko',
  Neuvola = 'Neuvola',
  Dokport = 'Dokport',
  Terveysportti = 'Terveysportti',
}

export enum FlowName {
  QAndA = 'search',
}

export enum QAndAFlowPath {
  Search = 'search',
  Results = 'results',
}

export enum ChatUsertype {
  Client = 'CLIENT',
  Professional = 'PROFESSIONAL',
}

export enum ChatSessionEndedReason {
  Error = 'ERROR',
  OtherUser = 'OTHER_USER',
  LeaveRequest = 'LEAVE_REQUEST',
}

export enum ReportType {
  Default = 'default',
  Youth = 'youth',
  Guardian = 'guardian',
  Combination = 'combination',
}

export enum ReportViewType {
  Summary = 'summary',
  Full = 'full',
  Pdf = 'pdf',
  Print = 'print',
}

export interface IChatUser {
  chatId: string;
  username: string;
  usertype: ChatUsertype;
  profession?: string;
  avatar?: string;
  time: string;
}
export interface IMessage {
  id: string;
  chatId: string;
  username: string;
  usertype: ChatUsertype;
  received: boolean;
  seen: boolean;
  time: string;
  text: string;
}

export interface IChatStatusMessage {
  title?: string | null;
  message: string;
  isError?: boolean;
}

export interface IState {
  logo?: string | null; // null means no custom logo, undefined means still loading
  modalIsOpen: boolean;
  appIsLoading: boolean;
  currentFlowItem?: FlowResponse;
}

export interface IAction {
  type: string;
  payload?: any;
}

export type Reducer = (state: IState, action: IAction) => IState;

export interface IStateContext {
  dispatch: (value: IAction) => void;
  state: IState;
}

// Extend Partial utility type to apply nested properties
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

// Locale using ISO Language Codes (http://www.lingoes.net/en/translator/langcode.htm)
export enum Locale {
  FI = 'fi',
  EN = 'en',
  SV = 'sv',
}

// TranslationKeys (nested translation keys, e.g. in the json translation file)
export type TranslationKeys = {
  [property: string]: string | TranslationKeys;
};

export type LanguageResources = {
  [key in Locale]?: TranslationKeys;
};

export interface IConfiguration {
  // If startMenu is string fetch options from the backend, otherwise use
  // provided options
  startMenu: string | GetStartMenuQuery;
  // Can user search or do we only provide a few options to select. If no search
  // then "Ask new question" link won't be shown in QA_RESULT feedback
  searchEnabled: boolean;
  colors: Colors;
  header: {
    appLogo: string;
    appLogoTranslated: Partial<Record<Locale, string>>;
    appLogoWidth: string;
    appLogoPaddingTop: string;
    showInfoToggle: boolean;
    progressBarVisible: boolean;
  };
  flowInit: {
    cardShadow: EffectProps['shadow'];
    cardStackPadding: SpaceProps['pt'];
    cardBorderRadius: BorderProps['borderRadius'];
    maxWidth: LayoutProps['maxWidth'];
    titleInsideCard: boolean;
  };
  flowitemConf: {
    showIntro: boolean;
    introMaxWidth?: LayoutProps['maxWidth'];
    introBgColor: string;
    introColor: string;
  };
  translations: {
    supportedLanguages: Locale[];
    resources: LanguageResources;
    fallbackLanguage: Locale;
    fallbackResources: Required<LanguageResources>;
  };
  chat: {
    enableChat: boolean;
    enableProChatView: boolean;
    isDevelopment?: boolean;
  };
  homeScreen: () => JSX.Element;
  styles: {
    inputBorderColor: string;
    searchBorderWidth: string;
    pageBgColor?: BackgroundProps['backgroundColor'];
    cardBgColor?: BackgroundProps['backgroundColor'];
    progressBarBgColor?: BackgroundProps['backgroundColor'];
  };
  radioStyles?: {
    color?: string;
    background?: string;
    borderColor?: string;
    fontSize?: string; // label font size. default 18px;
    colorScheme?: string;
    size?: 'sm' | 'md' | 'lg';
  };
  fonts?: {
    heading?: string;
    body?: string;
  };
  siteTokenVerification?: {
    dummyTokens: string[];
    siteToRedirect: string;
    tokenExpHours?: number; // default 24 hours
  };
}

export type IConfigurationPartial = RecursivePartial<IConfiguration>;
