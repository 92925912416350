import React, { useCallback, useContext, useMemo } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CardContentStack } from '~/src/components/Card';
import {
  AuthenticationMethod,
  Maybe,
  useGetAuthenticationMethodsQuery,
  useInitAuthenticationMutation,
} from '~/src/api';
import { AppContext } from '~/src/state';
import { authenticationImages } from '~/src/components/SurveyFlow/Authentication/imageMapper';
import { isValidUrl } from '~src/utils/isValidUrl';
import { devlogger } from '~src/utils';
import { FlowItemImage } from '../SurveyFlow/components/FlowItemImage';
import { FlowItemTitle } from '../SurveyFlow/components/FlowItemTitle';
import { FlowItemDescription } from '../SurveyFlow/components/FlowItemDescription';
import Loading from '../Loading';

// HOX: Needs to match backend and db enum values (kuura/chat/graphql/datasources/authentication.ts)
export enum AuthenticationStatus {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

const Authentication: React.FC<{
  userSessionId: string;
  callbackPath: string;
  title?: string | null;
  description?: Maybe<string[]>;
  imgUrl?: string | null;
  containerProps?: Record<string, unknown>;
}> = React.memo(
  ({
    userSessionId,
    callbackPath,
    title,
    description,
    imgUrl,
    containerProps,
  }) => {
    const { dispatch } = useContext(AppContext);
    const { t } = useTranslation();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const isRetry = urlParams.get('retry');

    const { data, loading: getMethodsLoading } =
      useGetAuthenticationMethodsQuery({
        skip: !userSessionId,
        fetchPolicy: 'cache-and-network',
        onCompleted() {
          dispatch({
            type: 'SET_APP_LOADING_STATE',
            payload: false,
          });
        },
      });

    const methods: AuthenticationMethod[] = useMemo(
      () => data?.authenticationMethods.methods || [],
      [data?.authenticationMethods.methods],
    );

    const [initAuth, { error, loading }] = useInitAuthenticationMutation({
      onError() {
        return dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      },
      onCompleted(data) {
        const { authUrl } = data.initAuthentication;
        if (isValidUrl(authUrl)) {
          window.location.href = authUrl || '';
        } else {
          dispatch({
            type: 'SET_APP_LOADING_STATE',
            payload: false,
          });
          devlogger('Invalid auth url', authUrl);
        }
      },
    });

    const getVariablesPayload = useCallback(
      (method: string) => {
        const { protocol, hostname, port } = window.location;
        const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
        return {
          // NOTE: answerId is actually Answer.UserSession.id
          id: userSessionId || '',
          data: {
            userSessionId: userSessionId,
            callback: `${baseUrl}${callbackPath}`,
            authMethod: method,
          },
        };
      },
      [callbackPath, userSessionId],
    );

    const methodsButtons = useMemo(
      () =>
        (methods || []).reduce((acc, method) => {
          acc.push(
            <Button
              key={method.id}
              onClick={() => {
                initAuth({
                  variables: getVariablesPayload(method.method || ''),
                });
              }}
              variant="unstyled"
              display="flex"
              height="auto"
              border="2px solid"
              borderRadius={0}
              borderColor="gray.100"
              _hover={{ bg: 'gray.100' }}
              minHeight="79px"
              flexBasis={['132px', '132px', '154px']}
              minWidth={['132px', '132px', '154px']}
            >
              <Image
                src={
                  authenticationImages[
                    method.id as keyof typeof authenticationImages
                  ]
                }
                p="4px"
                margin="8px"
                maxWidth={['109px', '109px', '125px']}
                alt={method?.description || ''}
                height="55px"
              />
            </Button>,
          );
          return acc;
        }, [] as JSX.Element[]),
      [methods, initAuth, getVariablesPayload],
    );

    return (
      <CardContentStack mb={[4, null, 8]} {...containerProps}>
        <FlowItemImage imgUrl={imgUrl} />
        {title && (
          <FlowItemTitle
            title={title || t('healthIssueFlow.authentication.title')}
          />
        )}
        <FlowItemDescription description={description} />
        {(!!error || isRetry) && (
          <Alert status="error" maxWidth={'640px'}>
            <AlertIcon />
            <AlertDescription mr={1}>
              {t(
                error
                  ? 'healthIssueFlow.authentication.initError'
                  : 'healthIssueFlow.authentication.retryError',
              )}
            </AlertDescription>
            {error && (
              <Tooltip label={error.message} placement="top">
                {/* wrap icon with span to prevent ref problem */}
                <span>
                  <AiOutlineQuestionCircle />
                </span>
              </Tooltip>
            )}
          </Alert>
        )}
        <Flex
          justifyContent="center"
          position="relative"
          flexWrap="wrap"
          gap="8px"
          width="100%"
          maxWidth="650px"
        >
          {loading && (
            <Flex
              align="center"
              background="whiteAlpha.600"
              height="full"
              justify="center"
              position="absolute"
              width="100%"
              zIndex="overlay"
            />
          )}
          {getMethodsLoading && (
            <Flex justifyContent="center">
              <Loading />
            </Flex>
          )}
          {methodsButtons}
        </Flex>
      </CardContentStack>
    );
  },
);

export { Authentication };
