import { ApolloError } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateGuestSessionMutation } from '~src/api';
import {
  getSessionStorage,
  removeSessionStorage,
  upsertSessionStorage,
} from '~src/utils/storage';
import { CHAT_LOGIN_URL_PATH } from './chat-context';
import { chatLogger } from './chat-utils';

const useChatTokens = (chatRoomId?: string) => {
  const history = useHistory();
  const chatStorage = getSessionStorage('chat');
  const accessTokenExpiresIn = chatStorage?.expiresIn || null;
  const userSessionId = chatStorage?.userSessionId || null;

  // Use `useRef` to store the interval ID to avoid reset on re-render
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Cleanup function for clearing the interval
  const clearTokenInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    removeSessionStorage('chat'); // Ensure token is removed
  };

  const [createGuestSession, createGuestSessionParams] =
    useCreateGuestSessionMutation({
      onError(error: ApolloError) {
        chatLogger('error', error);
        history.push(`${CHAT_LOGIN_URL_PATH}/${chatRoomId}`);
      },
      onCompleted(data) {
        chatLogger('D-Chat guest session created successful', data);

        clearTokenInterval();
        upsertSessionStorage('chat', {
          ...chatStorage,
          ...data.createGuestSession,
        });

        // Dispatch custom event to notify other hooks about the change
        const event = new Event('chatStorageUpdated');
        window.dispatchEvent(event);
      },
    });

  // accessToken
  const [accessToken, setAccessToken] = useState(() => {
    return chatStorage?.accessToken || null;
  });

  // Optional: set this effect to check sessionStorage at intervals (if needed)
  useEffect(() => {
    // Function to update accessToken from sessionStorage
    const updateAccessToken = () => {
      const chatStorage = getSessionStorage('chat');
      setAccessToken(chatStorage?.accessToken || null);
    };

    // Listen for the custom event triggered after session storage update
    const handleStorageUpdate = () => {
      updateAccessToken();
    };

    // Add event listener for custom event
    window.addEventListener('chatStorageUpdated', handleStorageUpdate);
    window.addEventListener('storage', handleStorageUpdate);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('chatStorageUpdated', handleStorageUpdate);
      window.removeEventListener('storage', handleStorageUpdate);
    };
  }, []);

  return {
    createGuestSession,
    createGuestSessionParams,
    accessToken,
    accessTokenExpiresIn,
    userSessionId,
    destroyChatSessionStorage: () => removeSessionStorage('chat'),
  };
};

export { useChatTokens };
